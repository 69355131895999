// colors.js
export const colors = {
    white: "#FFFFFF",
    charcoal: "#1c1919",
    lightGray: "#ebebf0",
    scarletRed: "#e63f3c",
    blue: "#4079e1",
    aqua: "#67d3c5",
    violet: "#6948a9",
    tangerine: "#ff9b39",
    lemon: "#ffd348",
    pistachioGreen: "#b6e276",
    red: "#c42724", // Crimson
    royalBlue: "#0855e1",
    turquoise: "#08bdbd",
    royalPurple: "#5123a9",
    orange: "#ff8207",
    goldenYellow: "#ffb302",
    lime: "#a5cb00",
    darkGray: "#403d3d",
    mediumGray: "#716e6e",
    gray: "#b0adad",
    containerGray: "#ebebf0a6",
};
