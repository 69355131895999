import React from "react";

export default function TopHeroComponent(props) {
  return (
    <>
      <div
        className="bg-cover"
        style={{
          backgroundColor: props.backgroundColor,
          backgroundImage: `URL(${props.backgroundImage})`,
        }}
      >
        <div className="container max-w-screen-xl w-full text-white items-center pb-10 lg:pb-14">
          <div className={`bg-white w-full rounded-b-[15px] px-10 py-[1.875rem] text-center mb-[15px] ${props.shadowColor}`}>
            <span className="text-[1.5rem] font-semibold text-medium-gray pb-2">
              {props.pageTitle}
            </span>
            <h1
              className="text-balance my-0"
              style={{
                color: props.fontShadowColor,
              }}
            >
              {props.title}
            </h1>
            <span className="text-charcoal text-pretty">{props.body}</span>
          </div>
        </div>
      </div>
    </>
  );
}
