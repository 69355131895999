import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TopHeroComponent from "../components/herocomponents/topherocomponent"
import Maximizing from "../components/bodycomponents/maximizing"
import R_BLUE_BG from "../images/background-images/bg-royal-blue.svg"
import { colors } from "../utils/colors"
import GRAY_ARROW_BG from "../images/SSLLC_Arrow_Movement.svg"
import WAREHOUSE from "../images/about-us/surplus-warehouse.jpg"
import EMPLOYEE_EQUIPMENT from "../images/about-us/employee-equipment.jpg"
import EMPLOYEE_WAREHOUSE from "../images/about-us/employee-warehouse.jpg"

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title={`Used Lab Equipment Dealer & Scientific Instrument Broker`}
        description={`Surplus Solutions is committed to providing quality used equipment and exceptional service for the pharmaceutical, nutraceutical, and biotech industries.`}
      />
      <TopHeroComponent
        backgroundImage={R_BLUE_BG}
        backgroundColor={colors.blue}
        fontShadowColor={colors.royalBlue}
        shadowColor="shadow-royalBlue-bottom"
        pageTitle="About Surplus Solutions"
        title="Helping you buy, sell, and maximize value."

      />
      <div className="shadow-custom-out">
        <div className="container max-w-screen-xl py-14">
          <div className="pb-5">
          <p>
              Surplus Solutions is a leader in buying and selling high-quality
              used lab, processing and packaging equipment, offering solutions
              that save you time and money while ensuring reliable performance.
              Since 2006, we’ve partnered with businesses in the pharmaceutical,
              nutraceutical, biotech, and life sciences industries to
              efficiently manage their lab assets—getting the best value for
              surplus products and sourcing the ideal equipment for specific
              needs, from lab analyzers and freezers to mixers and fillers.
            </p>
          </div>
          <div className="w-full flex flex-col md:flex-row md:space-x-12 py-5">
            <div className="w-full md:w-2/5 pb-8 md:pb-0">
              <img
                src={WAREHOUSE}
                alt="External shot of Surplus Solutions headquarters in Woonsocket, Rhode Island."
                className="rounded-[5px]"
              />
            </div>
            <div className="w-full md:w-3/5">
              <h3>Strategically Located</h3>
              <p className="pb-6">
                Headquartered in Woonsocket, RI, with nearly 200,000 sq ft of
                warehouse space across Rhode Island and California, we’re
                well-positioned to meet your equipment needs quickly and
                efficiently.
              </p>
              <p>
                With the 2024 acquisition of Fameco, a European leader in
                refurbished lab and medical equipment, we’re in an even better position to
                serve buyers and sellers around the world. A 40,000 sq ft
                facility in Vendenheim, France, expands our inventory to include
                refurbished analytical lab and medical instruments such as
                HPLCs, Gas Chromatography Systems, Endoscopes, Dialysis
                Machines, and Ventilators. All of this equipment is refurbished
                to meet the highest quality standards, so you can be sure of
                having reliable tools for your team’s critical work.
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col-reverse md:flex-row md:space-x-10 py-5">
            <div className="w-full md:w-3/4">
              <h3>Turnkey Service</h3>
              <p>
                We know that reselling equipment is about more than just dollars
                and cents—it’s an opportunity to make strategic investments that
                drive your business forward. That’s why we’re more than just a
                middleman. Our tailored sourcing services—including brokerage,
                consignment, and outright purchases—are strategically deployed
                to help you maximize the value of your idle assets. Our targeted
                marketing strategies and comprehensive listings, featuring
                detailed specifications and actual equipment images, ensure that
                you get the best return on your equipment. And our end-to-end
                service covers every detail, from appraisal to logistics,
                letting you focus on innovation while we ensure a seamless,
                efficient process.
              </p>
            </div>
            <div className="w-full md:w-1/4 pb-8 md:pb-0">
              <img
                src={EMPLOYEE_EQUIPMENT}
                alt="Technician performing a detailed assessment of lab equipment for resale potential in a warehouse setting."
                className="rounded-[5px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row md:space-x-10 space-y-8 py-5">
            <div className="w-full md:w-1/3">
              <img
                src={EMPLOYEE_WAREHOUSE}
                alt="Technician standing in front of Suplus Solutions warehouse facility."
                className="rounded-[5px]"
              />
            </div>
            <div className="w-full md:w-2/3">
              <h3>Extensive Experience</h3>
              <p className="pb-6">
                When you buy from Surplus Solutions, you also benefit from our
                decades of industry expertise and extensive network of buyers
                and sellers. Whether you’re looking to offload unneeded
                equipment, or in the market for lab chillers, glassware,
                filtration units, or more specialized instruments like gene
                sequencing tools, we’re your one-stop shop for buying and
                selling lab, processing, packaging, and facilities and office
                equipment.
              </p>
              <p>
                Find out what it’s like to partner with an industry leader—and
                the benefits it can hold for your organization. Contact us today
                to discuss how our customized approach and strategic focus can
                efficiently and affordably support your lab’s goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Maximizing
        backgroundImage={GRAY_ARROW_BG}
        title="Connect with Surplus Solutions"
        body={
          <p>
            Whether you’re buying or selling, reach out to our expert team to
            discover the best solutions for your used equipment needs.
          </p>
        }
        to="/sell-equipment#sell-equipment-form"
        buttonTitle="Let's Get Started"
        buttonColor={colors.royalBlue}
        hoverColor={colors.blue}
      />
    </Layout>
  )
}

export default AboutUs
