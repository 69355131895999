import React from "react";
import { Link } from "gatsby";

export default function Maximizing(props) {
  return (
    <div className="shadow-custom-out">
      <div className="container max-w-screen-xl w-full py-14">
        <div
          className="w-full flex flex-row items-center space-x-1 bg-no-repeat bg-cover bg-right rounded-[10px]"
          style={{
            backgroundImage: `url(${props.backgroundImage})`,
          }}
        >
          <div className="w-full md:w-2/3 bg-light-gray py-5 pr-6 pl-7 rounded-[10px]">
            <h3 className="">{props.title}</h3>
            <span>{props.body}</span>
            <Link to={props.to} className="flex md:hidden justify-left w-full pt-4">
              <button
                type="submit"
                className="text-white px-5 py-2 text-[1.2rem] rounded-[4px] transition duration-200"
                style={{
                  backgroundColor: props.buttonColor, // Regular background color
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = props.hoverColor)
                } // Hover background
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = props.buttonColor)
                } // Reset background
              >
                {props.buttonTitle}
              </button>
            </Link>
          </div>

          <div className="w-1/3 items-center p-6">
            <Link to={props.to} className="hidden md:flex justify-center w-full">
              <button
                type="submit"
                className="text-white px-5 py-2 text-[1.2rem] rounded-[4px] transition duration-200"
                style={{
                  backgroundColor: props.buttonColor, // Regular background color
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = props.hoverColor)
                } // Hover background
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = props.buttonColor)
                } // Reset background
              >
                {props.buttonTitle}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
